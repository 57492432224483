import * as React from 'react';
import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import InscriptionHero from '../../components/Inscription/InscriptionHero/InscriptionHero';
import { LocaleTypes } from '../../data/locales';
import InscriptionNewPaymentMethod from '../../components/Inscription/InscriptionNewPaymentMethod/InscriptionNewPaymentMethod';

interface InscriptionChilePageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const InscriptionChilePage: React.FC<InscriptionChilePageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO title="Activación de servicio adicional" />
      <InscriptionHero>
        <h1>Activación de servicio adicional</h1>
      </InscriptionHero>
      <InscriptionNewPaymentMethod/>
      <Footer theme="primary" />
    </>
  );

  return (
    <IndexLayout
      render={ content }
      locale={pathContext.localeCode}
      navbarTheme="light"
      location={location}
    />
  )
}

export default InscriptionChilePage;
